import { media, pseudo } from '@glitz/core';
import React, { useState } from 'react';
import { styled, theme } from '../Theme';
import { ErrorIcon } from '../Shared/Icons';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import KexLink from '../Shared/KexLink/KexLink';
import ContentDivider from '../Shared/Divider/Divider';
import UserCredentials from '../AccountPage/Models/UserCredentials.interface';
import { KexInputValidation } from '../Shared/Input/KexInputValidation';
import { signIn } from '../AccountPage/Account';
import KexInput from '../Shared/Input/KexInput';
import { useKexNavigate } from '../Shared/KexRouter/KexRouter';
import { FeedbackButton, SmallButton } from '../Shared/Button/Button';
import useMedia from '../Shared/Hooks/useMedia';
import XhtmlComponent from '../Shared/XhtmlComponent/XhtmlComponent';
import { loginDataLayer } from '../utils/dataLayer.lib';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';

type PropType = {
  closeModal: (id?: number) => void;
  title: string;
  registerUrl: string;
};

function LoginModalContent({ closeModal, title, registerUrl }: PropType) {
  const {
    translations: {
      'account/signIn': signInLabel,
      'account/email': emailLabel,
      'account/password': passwordLabel,
      'account/forgotPassword': forgotPasswordLabel,
      'account/linkAccountHeader': linkAccountHeader,
      'account/registerAccount': registerAccountLabel,
      'common/generalError': generalErrorLabel,
    },
    languageRoute,
    staticPages: { accountPage, resetPasswordPage },
    globalTexts: globalText,
    language,
  } = useAppSettingsData();
  const { canonicalLink } = useCurrentPage();
  const kexNavigate = useKexNavigate();
  const [password, setPassword] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);
  const [validationMessage, setValidationMessage] = useState<string>('');

  const disabled = password.length === 0 || username.length === 0;
  const isMobile = useMedia(theme.mediaQuery.mediaMaxLarge);

  const userCredentials: UserCredentials = {
    email: username,
    password: password,
    language: languageRoute,
  };
  const closeModalAndSignIn = async () => {
    const res = await signInUser();
    if (res.statusCode === 200) {
      loginDataLayer(language, languageRoute, canonicalLink, res?.userId);
      closeModal();
    }
  };
  const resetPasswordPressed = async () => {
    kexNavigate(resetPasswordPage);
    closeModal();
  };

  const registerAccountPressed = () => {
    kexNavigate(registerUrl);
    closeModal();
  };

  const signInUser = () => {
    if (disabled) return;

    return signIn(
      userCredentials,
      accountPage,
      setIsError,
      setValidationMessage,
      generalErrorLabel
    );
  };

  return (
    <FormContainer>
      <TitleWrapper>{title}</TitleWrapper>
      <SignInIntroText>
        <XhtmlComponent content={globalText.loginText} />
      </SignInIntroText>
      {isError && (
        <ValidationMessage>
          <StyledErrorIcon />
          <ErrorMessage>{validationMessage}</ErrorMessage>
        </ValidationMessage>
      )}
      <InputContainer>
        <KexInputValidation>
          <KexInput title={emailLabel} onChange={setUsername} />
          <KexInput
            title={passwordLabel}
            type="password"
            onChange={setPassword}
            onKeyDown={e => e.key === 'Enter' && closeModalAndSignIn()}
          />
        </KexInputValidation>
      </InputContainer>
      {isMobile && (
        <ForgotPasswordLink onClick={resetPasswordPressed}>
          {forgotPasswordLabel}
        </ForgotPasswordLink>
      )}
      <ButtonContainer>
        {!isMobile && (
          <ForgotPasswordLink onClick={resetPasswordPressed}>
            {forgotPasswordLabel}
          </ForgotPasswordLink>
        )}
        <LoginButton disabled={disabled} action={closeModalAndSignIn}>
          {signInLabel}
        </LoginButton>
      </ButtonContainer>
      <MaxDivider />
      <LinkAccountContainer>
        {linkAccountHeader}
        <LinkAccountText>
          <XhtmlComponent content={globalText.createAccountText} />
        </LinkAccountText>
      </LinkAccountContainer>
      <ButtonContainer>
        <CreateAccountButton onClick={registerAccountPressed}>
          {registerAccountLabel}
        </CreateAccountButton>
      </ButtonContainer>
    </FormContainer>
  );
}

const MaxDivider = styled(ContentDivider, {
  width: '100%',
  margin: { top: theme.spacing(4), bottom: theme.spacing(2) },
  ...media(theme.mediaQuery.mediaMinMedium, {
    margin: { y: theme.spacing(8) },
  }),
});

const SignInIntroText = styled.div({
  font: { size: theme.beta, weight: theme.fontWeight.normal },
  letterSpacing: theme.letterSpacing.nearNormalWide,
  ...media(theme.mediaQuery.mediaMinMedium, {
    fontSize: theme.gamma,
    letterSpacing: theme.letterSpacing.normalWide,
  }),
});

const InputContainer = styled.div({
  position: 'relative',
});

const TitleWrapper = styled.div({
  font: { size: theme.eta, weight: theme.fontWeight.bold },
  marginBottom: theme.spacing(8),
  ...media(theme.mediaQuery.mediaMaxMedium, {
    marginTop: theme.spacing(2),
  }),
});

const FormContainer = styled.div({
  font: { size: theme.eta, weight: theme.fontWeight.bold },
  color: theme.black,
  backgroundColor: theme.white,
  width: '100%',
  lineHeight: theme.lineHeight.normal,
  ...media(theme.mediaQuery.mediaMinMedium, {
    font: { size: theme.mu, weight: theme.fontWeight.bold },
    padding: {
      xy: theme.spacing(8),
    },
  }),
});

const ValidationMessage = styled.div({
  font: { size: theme.gamma, weight: theme.fontWeight.normal },
  lineHeight: theme.lineHeight.relaxed,
  backgroundColor: theme.errorPrimary,
  color: theme.errorText,
  padding: { xy: theme.spacing(4) },
  margin: { y: theme.spacing(4) },
  display: 'flex',
  alignItems: 'center',
});

const ErrorMessage = styled.p({
  fontSize: theme.gamma,
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: '0%',
  marginLeft: theme.spacing(2),
});

const ButtonContainer = styled.div({
  display: 'flex',
  ...media(theme.mediaQuery.mediaMaxMedium, {
    flexDirection: 'column',
  }),
  margin: { top: theme.spacing(8) },
});

const ForgotPasswordLink = styled(KexLink, {
  font: { size: theme.gamma, weight: theme.fontWeight.bold },
  margin: { y: 'auto' },
  textAlign: 'left',
  ':hover': {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
});

const StyledErrorIcon = styled(ErrorIcon, {
  fill: theme.errorText,
  width: theme.spacing(4),
  height: theme.spacing(4),
});

const LoginButton = styled(FeedbackButton, {
  font: { size: theme.gamma, weight: theme.fontWeight.normal },
  letterSpacing: theme.letterSpacing.medium,
  color: theme.white,
  width: '100%',
  height: theme.spacing(11.5),
  padding: { y: theme.none, x: theme.spacing(16) },

  marginLeft: 'auto',
  minWidth: theme.spacing(23),
  minHeight: theme.spacing(11),
  ...media(theme.mediaQuery.mediaMinLarge, {
    width: 'auto',
  }),
});

const CreateAccountButton = styled(SmallButton, {
  font: { size: theme.gamma, weight: theme.fontWeight.normal },
  letterSpacing: theme.letterSpacing.medium,
  backgroundColor: theme.white,
  color: theme.blueDark,
  height: theme.spacing(11.5),
  padding: { y: theme.spacing(2.5) },
  border: {
    xy: {
      style: 'solid',
      width: theme.spacing(0.5),
      color: theme.blueDark,
    },
  },
  ...media(theme.mediaQuery.mediaMinLarge, {
    width: '100%',
    height: 'auto',
    ...pseudo(':not(:last-child)', {
      margin: { right: theme.spacing(4) },
    }),
  }),
});

const LinkAccountContainer = styled.div({
  font: { size: theme.zeta },
  fontWeight: theme.fontWeight.normal,
  letterSpacing: theme.letterSpacing.wide,
});

const LinkAccountText = styled.div({
  font: { size: theme.beta, weight: theme.fontWeight.normal },
  letterSpacing: theme.letterSpacing.nearNormalWide,
  ...media(theme.mediaQuery.mediaMinMedium, {
    fontSize: theme.gamma,
    letterSpacing: theme.letterSpacing.normalWide,
  }),
});

export default LoginModalContent;
