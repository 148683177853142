import React from 'react';

import { styled, theme } from '../../../../Theme';
import { AccountIcon } from '../../../Icons';

type PropTypes = {
  content: string;
};

function NotificationBanner({ content }: PropTypes) {
  return (
    <>
      <Banner>
        <TextButton>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </TextButton>
      </Banner>
    </>
  );
}

const TextButton = styled.div({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
});

const Banner = styled.div({
  justifyContent: 'center',
  backgroundColor: theme.warningPrimary,
  display: 'flex',
  alignItems: 'center',
});

const IconCss = {
  width: theme.spacing(4),
  height: theme.spacing(5),
  fill: theme.black,
};

export default NotificationBanner;
