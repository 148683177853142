import { useEffect, useState } from 'react';
import useSWR, { mutate } from 'swr';
import Fetcher from '../Shared/Common/Fetcher';
import { IS_PRODUCTION_ENV } from '../Shared/Configs/EnvConfig';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import FullSearchResult from '../Search/Models/FullSearchResult.interface';
import { canUseDOM } from '../Shared/Common/Helpers';
import { EventDispatcher, ON_SEARCH } from '../Shared/Common/EventDispatcher';

let abortController: AbortController = new AbortController();
let hasMounted = false;

export interface SearchTypesInterface {
  Products: string;
  Content: string;
  Documents: string;
  Other: string;
  Empty: string;
}

export const SearchTypes: any = {
  Products: 'getProduct',
  Content: 'getContent',
  Documents: 'getDocument',
  Other: 'getOther',
  Empty: '',
};

export function InitSearchResult(
  query: string,
  categoryFilter: string,
  take?: string
) {
  const {
    staticPages: { searchPage },
    commerce,
  } = useAppSettingsData();
  const url =
    query === ''
      ? ''
      : `${searchPage}Search?query=${query}&getProduct=${!!commerce}&getContent=true&getFilters=true&getDocument=true${
          take ? '&take=' + take : ''
        }${categoryFilter ? '&topcategory=' + categoryFilter : ''}`;
  return BaseSearch(url, `${searchPage}Search?query=${query}`);
}

export async function GetFilteredSearchResult(
  searchPage: string,
  query: string,
  currentData: FullSearchResult,
  filter?: string
) {
  const url = `${searchPage}Search?query=${query}&getProduct=true&getFilters=true${
    filter ? `&topcategory=${filter}` : ''
  }`;
  const res = await FetchSearch(url);
  if (res) {
    let newRes = {
      ...currentData,
      productSearchResultCards: {
        ...res.productSearchResultCards,
        items: [...res.productSearchResultCards.items],
      },
      productFilters: [...res.productFilters],
    };

    if (res.productSearchResultVariations) {
      newRes.productSearchResultVariations = {
        ...res.productSearchResultVariations,
        items: [...res.productSearchResultVariations.items],
      };
    }
    mutate(`${searchPage}Search?query=${query}`, newRes, false);
  }
}

export async function GetSearchResult(
  searchPage: string,
  query: string,
  take: number,
  skip: number,
  type: string = SearchTypes.Products,
  currentData: FullSearchResult,
  commerce: boolean
) {
  const url = `${searchPage}Search?query=${query}&${type}=true&take=${take}&skip=${skip}&getFilters=true&getProduct=${!!commerce}`;
  const res = await FetchSearch(url);
  if (res) {
    let newRes;
    switch (type) {
      case SearchTypes.Products:
        newRes = {
          ...currentData,
          productSearchResultCards: {
            ...res.productSearchResultCards,
            items: [
              ...currentData.productSearchResultCards.items,
              ...res.productSearchResultCards.items,
            ],
          },
        };
        if (res.productSearchResultVariations) {
          newRes.productSearchResultVariations = {
            ...res.productSearchResultVariations,
            items: [
              ...currentData.productSearchResultVariations.items,
              ...res.productSearchResultVariations.items,
            ],
          };
        }
        break;
      case SearchTypes.Content:
        newRes = {
          ...currentData,
          contentSearchResult: {
            ...res.contentSearchResult,
            items: [
              ...currentData.contentSearchResult.items,
              ...res.contentSearchResult.items,
            ],
          },
        };
        break;
      default:
        newRes = res;
    }
    mutate(`${searchPage}Search?query=${query}`, newRes, false);
  }
}

export async function FetchSuggestions(
  query: string,
  searchPage: string,
  setSuggestions: (suggestions: string[]) => void
) {
  const url = `${searchPage}Suggestions?query=${query}`;
  const res = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
  const data = await res.json();
  setSuggestions(data);
}

export function InitCategoryItems(take?: string) {
  const url = canUseDOM()
    ? `${window.location.pathname}/GetCategoryItems${
        take ? '?take=' + take : ''
      }`
    : '';

  return BaseSearch(
    url,
    canUseDOM() ? `${window.location.pathname}/GetCategoryItems` : ''
  );
}

export async function GetCategoryItems(
  take: number,
  skip: number,
  currentData: FullSearchResult
) {
  const url = canUseDOM()
    ? `${window.location.pathname}/GetCategoryItems?take=${take}&skip=${skip}`
    : '';

  const res = await FetchSearch(url);
  if (res) {
    let newRes;
    newRes = {
      ...res,
      productSearchResultCards: {
        ...res.productSearchResultCards,
        items: [
          ...currentData.productSearchResultCards.items,
          ...res.productSearchResultCards.items,
        ],
      },
    };
    if (res.productSearchResultVariations) {
      newRes.productSearchResultVariations = {
        ...res.productSearchResultVariations,
        items: [
          ...currentData.productSearchResultVariations.items,
          ...res.productSearchResultVariations.items,
        ],
      };
    }

    mutate(`${window.location.pathname}/GetCategoryItems`, newRes, false);
  }
}

function BaseSearch(url: string, id: string) {
  const { pageCacheTime } = useAppSettingsData();

  const [searchResult, setSearchResult] = useState<FullSearchResult>(
    {} as FullSearchResult
  );
  const { data: fechedSearchResult } = useSWR<FullSearchResult>(
    id,
    () => FetchSearch(url),
    {
      initialData: undefined,
      revalidateOnFocus: false,
      dedupingInterval: pageCacheTime,
    }
  );

  useEffect(() => {
    if (!hasMounted) {
      hasMounted = true;
    } else {
      if (fechedSearchResult) {
        setSearchResult(fechedSearchResult);
      }
    }
  }, [fechedSearchResult]);

  return searchResult;
}

function FetchSearch(url: string) {
  abortController.abort();
  abortController = new AbortController();
  const signal = abortController.signal;
  EventDispatcher.dispatch(ON_SEARCH, true);

  return Fetcher<FullSearchResult, FullSearchResult>(
    url,
    signal,
    (data, resolve) => {
      resolve(data);
      EventDispatcher.dispatch(ON_SEARCH, false);
    }
  );
}
