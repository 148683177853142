import { createCookie, readCookie } from '../Common/Cookie';

export const shouldShowNotificationModal = (id: string): boolean => {
  const value = readCookie('notification_' + id);
  if (value && value === 'hide') {
    return false;
  }
  return true;
};

export const hideNotificationModal = (id: string): void => {
  createCookie('notification_' + id, 'hide');
};
