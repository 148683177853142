import { useEffect, useState } from 'react';
import useSWR, { mutate } from 'swr';
import IKexCart from './Models/KexCart.interface';
import Fetcher from '../Shared/Common/Fetcher';
import { IS_PRODUCTION_ENV } from '../Shared/Configs/EnvConfig';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import { CalculatedPrice } from '../ProductPage/AttributeArea/AttributeArea.types';

let abortController: AbortController = new AbortController();
let hasMounted = false;
let cartUrl = '/api/cart/';

type CartReturnType = {
  cart: IKexCart;
  isLoading: boolean;
};

export function GetCart(id: string = ''): CartReturnType {
  const tempCartObject: IKexCart = {} as IKexCart;
  const [cart, setCart] = useState<IKexCart>(tempCartObject);
  const { pageCacheTime } = useAppSettingsData();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { data: fectchedCart } = useSWR<IKexCart>(
    `${cartUrl}GetCart?id=${id}`,
    FetchCart,
    {
      initialData: undefined,
      revalidateOnFocus: IS_PRODUCTION_ENV,
      dedupingInterval: pageCacheTime,
    }
  );

  useEffect(() => {
    if (!hasMounted) {
      hasMounted = true;
    } else {
      if (fectchedCart) {
        setCart(fectchedCart);
        setIsLoading(false);
      }
    }
  }, [fectchedCart]);

  return { cart, isLoading };
}

export async function GetPrice(
  productCode: string,
  updateQty: number,
  packageKey: string,
  quantity: number,
  id: string
): Promise<CalculatedPrice> {
  const absoluteQty = Math.abs(quantity);
  const urlPackageQuantity = quantity >= 0 ? quantity : '%2D' + quantity * -1;
  let urlQuantity =
    quantity > 0 ? updateQty * absoluteQty : '%2D' + updateQty * absoluteQty;
  if (quantity === 0) {
    urlQuantity = '%2D' + updateQty;
  }
  const url = `${cartUrl}GetPrice?code=${productCode}&quantity=${urlQuantity}&packageKey=${packageKey}&packageQuantity=${urlPackageQuantity}`;
  const res = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
  return res.json();
}

export async function UpdateCart(
  productCode: string,
  updateQty: number,
  packageKey: string,
  quantity: number,
  id: string
) {
  const absoluteQty = Math.abs(quantity);
  const urlPackageQuantity = quantity >= 0 ? quantity : '%2D' + quantity * -1;
  let urlQuantity =
    quantity > 0 ? updateQty * absoluteQty : '%2D' + updateQty * absoluteQty;
  if (quantity === 0) {
    urlQuantity = '%2D' + updateQty;
  }
  const url = `${cartUrl}Update?code=${productCode}&quantity=${urlQuantity}&packageKey=${packageKey}&packageQuantity=${urlPackageQuantity}`;
  const res = await fetch(url, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
  });

  if (res.ok) {
    const { cart } = await res.json();
    mutate(`${cartUrl}GetCart?id=${id}`, cart, false);
    return true;
  }
  return false;
}

export async function EmptyCart(id: string) {
  mutate(`${cartUrl}GetCart`, { numberOfItems: 0 } as IKexCart, false);

  const res = await fetch(`${cartUrl}RemoveAllItems`, {
    method: 'DELETE',
  });
  if (res.ok) {
    const { cart } = await res.json();
    mutate(`${cartUrl}GetCart?id=${id}`, cart, false);
  }
}

function FetchCart(url: string) {
  abortController.abort();
  abortController = new AbortController();
  const signal = abortController.signal;

  return Fetcher<IKexCart, any>(url, signal, (data, resolve) => {
    resolve(data.cart);
  });
}

export async function ReorderCart(orderNumber: string, id: string) {
  const url = `${cartUrl}Reorder?orderNumber=${orderNumber}`;
  const res = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });

  if (res.ok) {
    const { cart } = await res.json();
    mutate(`${cartUrl}GetCart?id=${id}`, cart, false);
  } else {
    return res.statusText;
  }
}

export async function AddCouponCode(
  code: string,
  id: string
): Promise<string | undefined> {
  const url = `${cartUrl}AddCouponCode?code=${code}`;

  const res = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });
  if (res.ok) {
    const { cart, notification } = await res.json();
    mutate(`${cartUrl}GetCart?id=${id}`, cart, false);
    return notification.error;
  }
}

export async function RemoveCouponCode(code: string, id: string) {
  const url = `${cartUrl}RemoveCouponCode?code=${code}`;

  const res = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });
  if (res.ok) {
    const { cart } = await res.json();
    mutate(`${cartUrl}GetCart?id=${id}`, cart, false);
    return true;
  }
  return false;
}
