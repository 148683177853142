import React, { useCallback, useEffect, useRef, useState } from 'react';
import { theme } from '../../Theme';
import { media, pseudo } from '@glitz/core';
import { StyleOrStyleArray } from '@glitz/type';
import { styled, StyledProps } from '@glitz/react';
import { useKexInputValidation, ValidationParams } from './KexInputValidation';
import { InputType } from '../../Enums/InputType.enum';

type KexInputValidationType = {
  validation?: ValidationParams;
};

type KexInputType = StyledProps &
  KexInputValidationType & {
    onChange?: (value: string, name?: string) => void;
    onBlur?: (value: string) => void;
    onKeyDown?: (event: React.KeyboardEvent) => void;
    title: string;
    type?: string;
    name?: string;
    value?: string;
    disable?: boolean;
    useDebounce?: boolean;
    autoComplete?: boolean;
    inputType?: string;
    textAreaRows?: number;
    placeholder?: string;
    isDRT?: boolean;
  };

const KexInput = ({
  title,
  type = 'Text',
  onChange,
  onBlur,
  onKeyDown,
  compose,
  name,
  value,
  validation,
  disable,
  useDebounce = false,
  autoComplete = true,
  inputType = InputType.Input,
  textAreaRows = 3,
  placeholder,
  isDRT = false,
}: KexInputType) => {
  const validationDispatch = useKexInputValidation();

  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [hasTouched, setHasTouched] = useState<boolean>(false);
  const [hasValue, setHasValue] = useState<boolean>(!!value || false);
  const [warning, setWarning] = useState<boolean>(false);

  const doValidation = (currentValue: string) => {
    if (!!validation) {
      const errorMessage = validation.errorMessage || '';
      let errors = 0;

      if (validation.active !== undefined && !validation.active) {
        return;
      }
      if (validation.maxLength) {
        errors += overMaxLength(currentValue, validation.maxLength) ? 1 : 0;
      }
      if (validation.pattern) {
        errors += patternInvalid(currentValue, validation.pattern) ? 1 : 0;
      }
      if (validation.valueToMatch) {
        errors += matchesValue(currentValue, validation.valueToMatch) ? 1 : 0;
      }

      if (errors > 0) {
        setHasError(true);
        setErrorMessage(errorMessage);
        setWarning(true);
      } else {
        setHasError(false);
        setHasValue(!!currentValue);
        setWarning(false);
      }
    }
  };

  function overMaxLength(currentValue: string, maxLength: number) {
    const validationError = currentValue.length > maxLength;
    return validationError;
  }

  function patternInvalid(currentValue: string, pattern: RegExp) {
    const validationError = !pattern.test(currentValue);
    return validationError;
  }

  function matchesValue(currentValue: string, valueToMatch: string) {
    const validationError =
      valueToMatch.length === 0 || !(currentValue === valueToMatch);
    return validationError;
  }

  const onInputBlur = (
    e:
      | React.FocusEvent<HTMLInputElement>
      | React.FocusEvent<HTMLTextAreaElement>
  ) => {
    const currentValue: string = e.currentTarget.value;
    doValidation(currentValue);
    onBlur && onBlur(e.currentTarget.value);
  };

  const onInputFocus = (
    e:
      | React.FocusEvent<HTMLInputElement>
      | React.FocusEvent<HTMLTextAreaElement>
  ) => {
    if (!!validation && validation.onTouched) {
      validation.onTouched(name); // TODO: Refactor? While it's okay to use callbacks since they was passed down, I don't like the fact
    }

    setHasTouched(true);
  };

  const timer = useRef<any>(null);
  const debounce = useCallback((fn: () => void, delay: number) => {
    clearTimeout(timer.current);

    timer.current = setTimeout(() => {
      fn();
    }, delay);
  }, []);

  useEffect(() => {
    validationDispatch({
      type: 'register',
      name: name,
      payload: {
        hasTouched: hasTouched,
        hasError: hasError,
        hasValue: hasValue,
        ignore: disable,
        warning: warning,
        name: name,
      },
    });
    if (!!validation) {
      if (validation.backendValidation) {
        setHasError(true);
        setErrorMessage(validation.backendValidation.message);
      }
    }

    return () => {
      validationDispatch({ type: 'unregister', name: name, payload: {} });
    };
  }, [
    validationDispatch,
    name,
    hasTouched,
    hasError,
    hasValue,
    validation,
    disable,
    warning,
  ]);

  const inputStyles: object = {
    ...FormInputStyle,
    ...(disable && disabledInput),
    ...(hasError && FormInputError),
    ...(isDRT && drtKexInput),
  };

  return (
    <InputGroup css={compose()}>
      <FormLabel css={hasError ? FormLabelError : {}}>
        {hasError ? errorMessage : title}
      </FormLabel>
      {inputType === InputType.Input ? (
        <InputField
          disabled={disable}
          css={inputStyles}
          type={type}
          placeholder={placeholder}
          autoComplete={autoComplete ? 'on' : 'off'}
          onChange={e => {
            const currentValue = e.currentTarget.value;
            onChange && onChange(currentValue, name);
            useDebounce &&
              debounce(() => {
                doValidation(currentValue);
              }, 100);
          }}
          onBlur={e => {
            onInputBlur(e);
          }}
          onKeyDown={e => {
            onKeyDown && onKeyDown(e);
          }}
          onFocus={e => {
            onInputFocus(e);
          }}
          name={name}
          value={value}
        />
      ) : (
        <TextAreaInput
          rows={textAreaRows}
          css={inputStyles}
          maxLength={301}
          placeholder={placeholder}
          autoComplete={autoComplete ? 'on' : 'off'}
          onChange={e => {
            const currentValue = e.currentTarget.value;
            onChange && onChange(currentValue, name);
            doValidation(currentValue);
          }}
          onBlur={e => {
            onInputBlur(e);
          }}
          onKeyDown={e => {
            onKeyDown && onKeyDown(e);
          }}
          onFocus={e => {
            onInputFocus(e);
          }}
          name={name}
          value={value}
        />
      )}
    </InputGroup>
  );
};

const InputGroup = styled.div({
  position: 'relative',
  display: 'flex',
  backgroundColor: theme.white,
});

const FormLabel = styled.label({
  font: { size: theme.tau, weight: theme.fontWeight.bold },
  margin: { x: theme.spacing(3), y: theme.tiny },
  padding: { x: theme.spacing(3) },
  position: 'absolute',
  backgroundColor: 'inherit',
});

const FormInputStyle: StyleOrStyleArray = {
  font: { size: theme.gamma, weight: theme.fontWeight.normal },
  letterSpacing: theme.letterSpacing.wide,
  backgroundColor: 'inherit',
  width: '100%',
  margin: { y: theme.spacing(2) },
  padding: { x: theme.spacing(3) },
  border: {
    xy: {
      style: 'solid',
      width: theme.tiny,
      color: theme.black,
    },
  },
  ...media(theme.mediaQuery.mediaMinLarge, {
    width: '100%',
  }),
  ...pseudo('-webkit-box-shadow', {
    backgroundColor: theme.white,
    border: { xy: { width: theme.spacing(2), color: theme.white } },
  }),
  WebkitBoxShadow: `0 0 0 30px ${theme.white} inset !important`,
  outline: { style: 'none' },
};

const TextAreaInput = styled.textarea({
  padding: { xy: theme.spacing(4) },
  height: 'auto',
});

const InputField = styled.input({ height: theme.spacing(11) });

const disabledInput: StyleOrStyleArray = {
  border: {
    xy: {
      style: 'solid',
      width: theme.tiny,
      color: theme.gray,
    },
  },
  font: { size: theme.gamma, weight: theme.fontWeight.bold },
  color: theme.gray,
};

const FormInputError: StyleOrStyleArray = {
  border: {
    xy: {
      style: 'solid',
      width: theme.tiny,
      color: theme.errorBorder,
    },
  },
};

const drtKexInput: StyleOrStyleArray = {
  borderRadius: theme.spacing(),
  padding: {
    x: theme.spacing(4),
    y: theme.spacing(3),
  },
  border: {
    xy: {
      width: theme.spacing(0.25),
      style: 'solid',
      color: theme.fairGray,
    },
  },
};

const FormLabelError: StyleOrStyleArray = {
  color: theme.errorText,
};

export default styled(KexInput);
