import React from 'react';
import { styled, theme } from '../../Theme';
import { StyledProps } from '@glitz/react';

type Divider = StyledProps;

const ContentDivider = ({ compose }: Divider) => {
  return <Base css={compose()} />;
};

const Base = styled.div({
  height: theme.tiny,
  width: theme.spacing(16),
  margin: { y: theme.spacing(6) },
  backgroundColor: theme.primary,
});

export default styled(ContentDivider);
