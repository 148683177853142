import { media } from '@glitz/core';
import { styled } from '@glitz/react';
import React from 'react';
import ImageModel from '../../Assets/Models/ImageModel.interface';
import { theme } from '../../Theme';
import Modal from '../Modal/Modal';
interface INotificationModalProps {
  popupTitle?: string;
  popupImage?: ImageModel;
  popupContent?: string;
  closeNotification: () => void;
}
export const NotificationModal = ({
  popupTitle,
  popupImage,
  popupContent,
  closeNotification,
}: INotificationModalProps) => {
  return (
    <Modal overlay isNotification hasCloseButton toggle={closeNotification}>
      <NotificationModalContent>
        <NotificationModalTitle>{popupTitle}</NotificationModalTitle>
        <NotificationModalBody>
          {popupImage && <NotificationModalImage src={popupImage.src} />}
          {popupContent && (
            <NotificationModalText
              dangerouslySetInnerHTML={{ __html: popupContent }}
            />
          )}
        </NotificationModalBody>
      </NotificationModalContent>
    </Modal>
  );
};

const NotificationModalImage = styled.img({
  flex: { grow: 1, shrink: 1, basis: 'auto' },
  width: '50%',
  objectFit: 'contain',
  objectPosition: 'top',
  ...media(theme.mediaQuery.mediaMaxLarge, {
    display: 'none',
  }),
});

const NotificationModalText = styled.div({
  flex: { grow: 1, shrink: 1, basis: 'auto' },
});
const NotificationModalContent = styled.div({
  width: '100%',
});

const NotificationModalTitle = styled.h2({
  width: '100%',
  textAlign: 'center',
  height: '3em',
  lineHeight: '3em',
  fontWeight: 'bold',
});

const NotificationModalBody = styled.div({
  width: '100%',
  display: 'flex',
  gap: theme.spacing(4) + 'px',
  padding: { xy: theme.spacing(6) },
});
